import React, { useEffect, useState } from "react";
import { UserToken } from "../User/UserToken";
import { Modules } from "./Modules";
import dayjs from "dayjs";
export const Student = () => {
  const userToken = new UserToken();
  const [coach, setCoach] = useState(null);
  const [students, setStudents] = useState(null);
  const [error, setError] = useState(false);
  const API_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    fetch(`${API_URL}/coach/students`, {
      headers: {
        Authorization: `Bearer ${userToken.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then(
        (data) => {
          setCoach(data);
          return;
        },
        (error) => {
          setError(error);
        }
      );
  }, []);

  useEffect(() => {
    fetch(`${API_URL}/coach/allstudents`, {
      headers: {
        Authorization: `Bearer ${userToken.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then(
        (data) => {
          setStudents(data);
          return;
        },
        (error) => {
          setError(error);
        }
      );
  }, []);

  useEffect(() => {
    renderModules();
  }, [coach]);

  useEffect(() => {
    renderStudents();
  }, [students]);

  useEffect(() => {
    //window.location.href = "/login";
  }, [error]);

  const renderModules = () => {
    if (!coach) {
      return <div className="loading"> </div>;
    }
    return coach.map((student) => {
      return (
        <>
          <div className="module-studentname">
            {student.name} {student.surname}{" "}
        
          </div>
          <Modules modules={student.modulecards} />
        </>
      );
    });
  };

  const renderStudents = () => {
    if (!students) {
      return <div className="loading"> </div>;
    }
    return students.map((student) => {
      return (
        <>
          {" "}
          <div class=" col-12">
        
            {" "}
            {student.name} {student.surname}{" "}
          
            {" "}
           
            <a
              onClick={() =>
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_CALENDAR_URL}/${student.calendarhash}`
                )
              }
              className="btn btn-primary badge badge-light"
            >
              Kalenderlink Kopieren
            </a>
          </div>
        </>
      );
    });
  };

  return (
    <div className="container students">
      <div className="row">
        <h2>Zu betreuende Module</h2>
        {renderModules()}
      </div>
<br/><br/>
      <div className="row">
        <h2>Zu betreuende Lernende</h2>
        {renderStudents()}
      </div>
    </div>
  );
};
