import { Modul } from "../Modul";
import { Workdays } from "./Workdays";
import { useDroppable } from "@dnd-kit/core";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React, { useState, useEffect, useRef } from "react";
import { holidays } from "./Holiday";

export function Masterplan({
  modulecards,
  state,
  col,
  selectmodule,
  comparator,
  workdays
}) {
  const [heigth, setHeight] = useState(0); 
  const [rowHeight, setRowHeight] = useState(0);
  const [modColWidth, setModColWidth] = useState(0);
  const refRow = useRef(null);
  const refModCol = useRef(null);
  const ref = useRef(null);
  const { setNodeRef } = useDroppable({
		id: `${state}`,
	});
 
  class RowInfo {
    constructor(height, startdate, enddate) {
      dayjs.extend(isBetween);
      this.height = height;
      this.startdate = startdate; 
      this.enddate = enddate;
      this.isBetween = (date) => {
        return dayjs(date).isBetween(this.startdate, this.enddate, "d", "[]");
      };
    }
  }

  const printCard = (card) => {
   
    return (
      <div
         key={"mw-"+card.module.id}
          className="modulwrapper"
          style={{ top: card.top, height: card.height, width: modColWidth }}
        >
          <Modul
            modulecard={card}
            key={card.module.id}
            selectModule={selectmodule}
          />
        </div>
    );
  };
  const rowInfos = [];

  if (!comparator) {
    comparator = (a, b) => a.module.number - b.module.number;
  }

  const getRows = () => {
    let rows = [];
    for (let i = 0; i < 42; i++) { 
      let currentMonday = dayjs().startOf('week').add(1,"d") ; 
      const date = dayjs(currentMonday).add(7 * (i - 6), "d");

      dayjs.extend(isBetween); 
     
      let isHoliday = holidays.find((holidayEntry) => {return dayjs(date).isBetween(holidayEntry.from, holidayEntry.to, "day","[]") }) != undefined;

      let info = new RowInfo(
        rowHeight + rowHeight * i,
        date,
        dayjs(date).add(7, "d")
      );
      rowInfos.push(info);
      let currentWorkdays = workdays.filter((workday) => {
        return info.isBetween(workday.Date);
      });
      rows.push(
        <tr key={i} ref={refRow} >
          <td >{date.format("DD.MM")}</td>
          <td ref={refModCol}></td> 
          <td className={isHoliday?"holiday wd":"wd"}>
            <Workdays startdate={date} workdays={currentWorkdays}/>
          </td>
        </tr>
      );
    }
    return rows;
  };

  useEffect(() => {
    setHeight(ref.current.clientHeight);
    setRowHeight(refRow.current.clientHeight);
    setModColWidth(refModCol.current.clientWidth);
    
  });

  const printCards = () => {
    
    const c = calculateCards();
  
    return c.map((card)=>printCard(card));
  };

  const calculateCards = () => {
    return modulecards
      .filter(function (modulecard) {
        return modulecard.state.state === state;
      })
      .sort((a, b) => comparator(a, b))
      .map((modulecard) => {
        modulecard.top = "-50px";
        rowInfos.map((rowInfo) => {
          if (modulecard.startdate && rowInfo.isBetween(modulecard.startdate)) {
            modulecard.top = rowInfo.height;
          }
          if (modulecard.enddate && rowInfo.isBetween(modulecard.enddate)) {
            modulecard.height = rowHeight + rowInfo.height - modulecard.top;
          }
        });
        return modulecard;
      });
  };

  return (
    <div ref={setNodeRef} className={`state ${state} ${col}`}>
      <h2>
        Masterplan ( 
        {
          modulecards.filter(function (module) {
            return module.state.state === state;
          }).length
        }
        )
      </h2>
      <div className="masterplanwrapper">
        <table ref={ref} className="masterplan">
          <tbody>
            <tr>
              <th width="35px">Datum</th>
              <th class="modulecol">Module</th>
              <th class="wd">Schultage</th>
            </tr>
            {getRows().map((row) => {
              return row;
            })}
          </tbody>
        </table>
        {printCards()}
      </div>
    </div>
  );
}
