import { UserToken } from "../User/UserToken";

export const Info = () => {
	const printModuleInfo = () => {
		if(userToken.isCoach()){
			return(
				<>
				{printAppInfo()}
				{printMedInfo()}
				</>
			);
		}
		if (userToken.getSpecialisation() === "Applikationsentwicklung") {
			return printAppInfo();
		} else if (userToken.getSpecialisation() === "Mediamatik") {
			return printMedInfo();
		}
	};

	const printAppInfo = () => {
        return (
		<div className="col-6">
			<h2>Übersicht Module Applikationsentwicklung</h2>
			<p>
				Gesamte Ausbildung: 31 Module <br /> (28 Pflicht-Module und 3 von den 12
				Wahl-Pflicht-Modulen )
			</p>
			<p>Richtwert pro Modul: 40 Lektionen = 5 Tage zu 8 Lektionen.</p>
			<p>
				Es gibt bei Lernvolution keinen Unterschied zwischen ÜK und
				Berufsschulmodulen
			</p>
			<p>Berufskunde über 4 Lehrjahre insgesamt: 31*5 = 155 Tage</p>
			<p>Vorschlag nach Bildungsverordnung: </p>

			<ul>
				<li>1. Lehrjahr: 11 Module ={">"} 55 Tage</li>
				<li>2. Lehrjahr: 11 Module ={">"} 55 Tage</li>
				<li>3. Lehrjahr: 5 Module ={">"} 25 Tage</li>
				<li>4. Lehrjahr: 4 Module ={">"} 20 Tage</li>
			</ul>
		</div>);
	};

	const printMedInfo = () => {
		return (
			<div className="col-6">
				<h2>Übersicht Module Mediamatik</h2>

				<a
					target="_blank"
					href="https://bbbaden.sharepoint.com/sites/LernvolutionBBB/_layouts/15/Doc.aspx?sourcedoc={4012fb2b-4532-4128-9420-94de168947b1}&action=edit&wd=target%28Lernvolution.one%7Cbcb2e40e-b430-463f-b3c0-ab373cce0e48%2FMediamatik%20mit%20BM%20%C3%9Cbersicht%20Module%20und%20Schultage%7C3a171f5b-b87f-4075-b6cd-0355712aa48e%2F%29&wdorigin=703"
					rel="noreferrer"
				>
					Erklärung auf OneNote für die Lernenden mit BM
				</a>
				<br />
				<a
					target="_blank"
					href="https://bbbaden.sharepoint.com/sites/LernvolutionBBB/_layouts/15/Doc.aspx?sourcedoc={4012fb2b-4532-4128-9420-94de168947b1}&action=edit&wd=target%28Lernvolution.one%7Cbcb2e40e-b430-463f-b3c0-ab373cce0e48%2FMediamatik%20ohne%20BM%20%C3%9Cbersicht%20Module%20und%20Schultage%7Cb7e93c50-46b8-4eb8-a001-84b2c863b893%2F%29&wdorigin=703"
					rel="noreferrer"
				>
					Erklärung auf OneNote für die Lernenden ohne BM
				</a>
			</div>
		);
	};

	const userToken = new UserToken();
	if (!userToken.isLoggedIn()) window.location.href = "/login";
	const calhash = userToken.getCalHash();
	return (
		<>
			<div className="container info">
				<br />
				<br />
				<br />
				<h1>Info für {userToken.getName()}</h1>

				<div className="row">
					<div className="col-6">
						<h2>Dein Online-Kalender</h2>
						<p>
							Diesen Link kannst du mit Leuten teilen, die im Betrieb oder in
							der Schule mit dir zu tun haben. Der Kalender aktualisert sich
							automatisch, wenn du im Modulplanner Dinge anpasst. So sind alle
							immer informiert wenn sich was ändert.
						</p>
						{process.env.REACT_APP_CALENDAR_URL}/{calhash}{" "}
						<a
							onClick={() =>
								navigator.clipboard.writeText(
									`${process.env.REACT_APP_CALENDAR_URL}/${calhash}`,
								)
							}
							className="btn btn-success badge"
						>
							Link Kopieren
						</a>
					</div>

					{printModuleInfo()}

					<div className="col-3">
						<img src="/logo.svg" alt="logo"></img>
					</div>
				</div>
			</div>
		</>
	);
};
