import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { useRef } from "react";

export function Modul({ modulecard, selectModule }) {
  const modul = modulecard.module;
  const moduleName = modul.name;
  const modulecardId = modulecard.id;
  const modulref = useRef(null);

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: modulecardId
  });
  const style = {
    transform: CSS.Translate.toString(transform),
  };

  const getTruncatedName = (modulename, height) => {
    if (height > 3 * 17) {
      return modulename;
    } else {
      if (moduleName.length > 50) {
        return modulename.substring(0, 50) + "...";
      } else {
        return modulename;
      }
    }
  };

  const showDetail = () => {
    selectModule(modulref.current.id);
  };

const getTypeClass = (type) => {
    switch (type) {
      case "Schul-Pflicht":
        return "schulpflicht";
      case "Wahl-ÜK":
        return "wahluek";
      case "ÜK":
        return "uek";
    }
      return "";
  };

  const getTypeName = (type) => {
    switch (type) {
      case "Schul-Pflicht":
        return "";
      case "Wahl-ÜK":
        return "(wÜK)";
      case "ÜK":
        return "(ÜK)";
    }
      return "";
  };
  let col = "col-12";
  if (
    modulecard.state.state === "Offen" ||
    modulecard.state.state === "Abgeschlossen"
  ) {
    col = "col-xl-6 col-12"; 
  }

  let dependencies = modul.dependencies.length > 0?"<":"";
  

  return (
    <>
      <div
        style={{ transform: style.transform }}
        onClick={showDetail}
        ref={setNodeRef}
        className={`modul card ${col} ${getTypeClass(modul.type)}`}
        id={`sm-${modulecardId}`}
      >
        <div className="card-body" ref={modulref} id={modulecardId}>
          <h6 className="card-subtitle mb-2">
            {modul.number + " "}  |
            {" " + getTruncatedName(modul.name, modulecard.height)}{" "}
            {getTypeName(modul.type)} </h6>
        </div>
        <div className="draggable" {...listeners} {...attributes}>
          ..
        </div>
      </div>
    </>
  );
}
