import dayjs from "dayjs";
export const Modules = ({modules}) => {
    return(
 modules.map((module) => {
    
   return (
    <div className="row">
    <div className="col-12 module-container">
    <div className={GetDateClass(module.startdate.date,module.enddate.date)}>
     {dayjs(module.startdate.date).format("DD.MM.YY")}-{dayjs(module.enddate.date).format("DD.MM.YY")}
     </div>
 <div className="module-title">
      {module.module.number} {module.module.name}
     </div>
    
     <div className="module-assessment">
     {module.assessment}
     </div>
     <div className="module-sequence">
     {module.sequence}
     </div>
    </div>
    </div>
   );
 }));

}

const GetDateClass = (startdate,enddate) => {
    const today = dayjs();
    const start = dayjs(startdate);
    const end = dayjs(enddate);
    if (today.isAfter(end)) {
    
      return "module-overdue";
    } else if (today.isAfter(start)) {
      return "module-inprogress";
    } else {
      return "";
    }
  }
    