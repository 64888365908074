export const Dependencies = ({dependencies}) => {
	if (dependencies.length > 0) {
		return (
			<>
				<p>Abhängigkeiten</p>
				<ul>
					{dependencies.map((dependency) => (
						<li>
							{dependency.number} - {dependency.name}
						</li>
					))}
				</ul>
			</>
		);
	} else {
        return (
        <p>Das Modul hat keine expliziten Abhängigkeiten zu anderen Modulen. Das Lehrjahr und das Objekt geben Hinweise auf die notwendige Vorerfahrung.</p>
        );
    }
};
