import { Moduleplanner } from "./Components/Board/Modulplanner";
import { Layout } from "./Components/Layout/Layout";
import { Login } from "./Components/Login/Login";
import { Info } from "./Components/Info/Info";
import { Student } from "./Components/Student/Student";
import { NotFound } from "./Components/Layout/NotFound";
import { UserToken } from "./Components/User/UserToken";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";

const getDefault = () => {
  
	const userToken = new UserToken();
  
	if (userToken.isLoggedIn()) {
   
		if (userToken.isCoach()) {
			return <Student />;
		} else {
			return <Moduleplanner />;
		}
	} else {
		return <Login />;
	}
};

function App() {
	return (
		<>
	
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={getDefault()} />
						<Route path="login" element={<Login />} />
						<Route path="students" element={<Student />} />
						<Route path="planner" element={<Moduleplanner />} />
						<Route path="info" element={<Info />} />
						<Route path="*" element={<NotFound />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;

// Reload page on resize to prevent layout issues (hacky)
window.addEventListener("resize", () => {
	//window.location.reload();
});
