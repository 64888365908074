import {User } from './User.js';

export const Navigation =()=>  {
	return (
		<div className="navigation">
			<nav className="navbar top">
				<div className="container-fluid">
					<span className="logotitle"><a href="/"><img src="/logo2.png" alt="logo"></img>ModulPlanner</a></span>
                    <User/>
				</div>
			</nav>
		</div>
	);
}
