export const holidays = [
//22/23
    { from: "2023-02-06", to: "2023-02-19" },
    { from: "2023-04-10", to: "2023-04-23" },
    { from: "2023-07-10", to: "2023-08-13" },
//23/24
    { from: "2023-10-02", to: "2023-10-15" },
    { from: "2023-12-25", to: "2024-01-07" },
    { from: "2024-02-05", to: "2024-02-18" },
    { from: "2024-04-08", to: "2024-04-21" },
    { from: "2024-07-08", to: "2024-08-11" },

//24/25
    { from: "2024-09-30", to: "2024-10-13" },
    { from: "2024-12-23", to: "2025-01-05" },
    { from: "2025-02-03", to: "2025-02-16" },
    { from: "2025-04-07", to: "2025-04-20" },
    { from: "2025-07-07", to: "2025-08-10" },

    //25/26
    { from: "2025-09-29", to: "2025-10-12" },
    { from: "2025-12-22", to: "2026-01-04" },
    { from: "2026-02-02", to: "2026-02-15" },
    { from: "2026-04-06", to: "2026-04-19" },
    { from: "2026-07-06", to: "2026-08-09" },

];