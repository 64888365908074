import dayjs from "dayjs";
import jwt from "jwt-decode";

let currentWorkday;
const API_URL = process.env.REACT_APP_BACKEND_URL;

export const Workday = ({ date, weekday, workdays: selecedWorkdays }) => {
	let selectedclass = "";
	currentWorkday = selecedWorkdays.find(
		(selectedWorkday) =>
			dayjs(selectedWorkday.Date).format("YYYY-MM-DD") ===
			date.format("YYYY-MM-DD"),
	);
	if (currentWorkday === undefined) {
		currentWorkday = {
			id: null,
			Date: date,
		};
	} else {
		selectedclass = "workday-set";
	}

	return (
		// rome-ignore lint/a11y/useButtonType: <explanation>
		<button
			onClick={(e) => clickHandler(e)}
			title={dayjs(currentWorkday.Date).format("YYYY-MM-DD")}
			className={`workday ${selectedclass}`}
			id={currentWorkday.id}
		>
			{weekday}
		</button>
	);
};

const getToken = () => {
	return localStorage.getItem("token");
};

const getUserId = () => {
	const decodedToken = jwt(getToken());
	return decodedToken.userid;
};

const clickHandler = (e) => {
	if (e.target.classList.contains("saving")) return;
	if (e.target.classList.contains("workday-set")) {
		removeDate(e);
	} else {
		console.log(e.target.title);
		const currentWorkday = {
			id: e.target.id,
			Date: e.target.title,
		};
		addDate(e, currentWorkday);
	}
};

const addDate = (e, currentWorkday) => {
	console.log(currentWorkday);
	e.target.classList.add("saving");
	fetch(`${API_URL}/workdays`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({
			date: dayjs(currentWorkday.Date).format("YYYY-MM-DD"),
			User: `/api/users/${getUserId()}`,
		}),
	})
		.then((res) => res.json())
		.then(
			(result) => {
				currentWorkday.id = result.id;
				currentWorkday.User = result.User;
				e.target.classList.add("workday-set");
				e.target.id = result.id;
			}
			
		).catch((error) => {
			e.target.classList.add("error");
		})
		.finally(() => {
			e.target.classList.remove("saving");
		});
};

const removeDate = (e) => {
	e.target.classList.add("saving");
	fetch(`${API_URL}/workdays/${e.target.id}`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getToken()}`,
		},
	})
		.then(() => {
			e.target.classList.remove("workday-set"); //Delete returns no content, so we have to remove the class here
			e.target.removeAttribute("id");
		})
		.finally(() => {
			e.target.classList.remove("saving");
		});
};
