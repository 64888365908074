import React, { useState } from 'react';
import { UserToken } from '../User/UserToken';
const errorLabel = document.createElement("div");
          errorLabel.innerHTML = "Falsches E-Mail oder Passwort angegeben";
          errorLabel.classList.add("error");

async function loginUser(credentials) {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/login_check`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        credentials
      )
    })
      .then(data => data.json())
      .then(data => {
        if(data.token){
          const user = new UserToken();
          user.login(data.token);
          return data.token;
        }else{
          return false;
        }
      }).catch((error) => {
        
        return false;
      });
   }

export const Login = () => {

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    
    document.querySelector(".error")?.remove();
    const handleSubmit = async e => {
        e.preventDefault();
       document.querySelector(".login button").innerHTML = "Überprüfung ...";
        const token = await loginUser({
          username,
          password
        });
        document.querySelector(".login button").innerHTML = "Einloggen";

        if(token){
            window.location.href = "/";
        }else{
   
          document.querySelector(".login__container form").prepend(errorLabel);
        }  
      }

    return (
      <div className="row">
        <div className="login col-1 col-md-2"><img className="login-img" src="modules.gif" alt="modules" /></div>
        <div className="login col-10 col-md-4">
            <div className="login__container">
                <h1>Willkommen</h1>
                <form onSubmit={handleSubmit}>

                    <h5>E-mail</h5>
                    <input type="text" onChange={e => setUserName(e.target.value)}/>   
                    <h5>Password</h5>
                    <input type="password" onChange={e => setPassword(e.target.value)} />
                    <br/><br/>
                    <button ype="submit" className="login__signInButton" >Einloggen</button>
                </form>
                <br/>
                <a href="https://api.itmodul.ch/reset-password">Passwort vergessen?</a>

            </div>

        </div>
        <div className="login col-1 col-md-2" />
        </div>
    )   
}
