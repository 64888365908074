import { Modul } from "./Modul";
import { useDroppable } from "@dnd-kit/core";


export function Column({ modulecards, state, col , selectmodule, comparator}) {
	const { setNodeRef } = useDroppable({
		id: `${state}`,
	});
	if(!comparator){
		comparator = (a,b)=>a.module.number-b.module.number;
	}
	return (
		
		<div ref={setNodeRef} className={`state  state-${state} ${col}`}>
			<h2>
				{state} (
	 			{
					modulecards.filter(function (card) {
						return card.state.state === state;
					}).length
				}
				)
			</h2>
				<div className="modules">
			{modulecards
				.filter(function (modulecard) {
					return modulecard.state.state === state;
				})
				.sort((a,b)=>comparator(a,b))
				.map((modulecard) => (
					<Modul modulecard={modulecard}  key={modulecard.module.id} selectModule={selectmodule}/>
				))}
				</div>
				</div>
	);
}
