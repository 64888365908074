import { Dependencies } from "./Dependencies";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { UserToken } from "../User/UserToken";


export function Moduldetails({ card, saveModulecard, onCardchange }) {
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const userToken = new UserToken();

  const [rerender, setRerender] = useState(false);
  const [coaches, setCoaches] = useState([]);

  const [modulecard, setModulecard] = useState(card);
  const [startdate, setStartdate] = useState(
    dayjs(card.startdate).format("YYYY-MM-DD")
  );
  const [enddate, setEnddate] = useState(
    dayjs(card.enddate).format("YYYY-MM-DD")
  );

  const [assessment, setAssessment] = useState(card.assessment?card.assessment:"Nicht definiert");
  const [sequence, setSequence] = useState(card.sequence?card.sequence:"Nicht definiert");
  const [coachId, setCoachId] = useState(card.coachId?card.coachId:null);


  const saveAgreement = (e) => {
    e.preventDefault();
    if(coachId == "" || coachId == null){
      return;
    }
    const body = {
      startdate: startdate,
      enddate: enddate,
      assessment: assessment,
      sequence: sequence,
      coach: "/api/users/"+coachId, //IRI
    };

    saveModulecard(modulecard.id, body);
    modulecard.startdate = dayjs(startdate).format("YYYY-MM-DD");
    modulecard.enddate = dayjs(enddate).format("YYYY-MM-DD");
    modulecard.assessment = assessment;
    modulecard.sequence = sequence;
    modulecard.coachId = coachId;
    onCardchange(modulecard);
    setRerender(!rerender);
  };

  const loadCoaches = () => {
    fetch(`${API_URL}/coach`, {
      headers: {
        Authorization: `Bearer ${userToken.getToken()}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.code === 401) {
            window.location.href = "/login";
          } else {
            setCoaches(result);
          }
        },
        (error) => {
        }
      );
  };
  useEffect(() => {
    setStartdate(card.startdate?dayjs(card.startdate).format("YYYY-MM-DD"):"");
    setEnddate(card.enddate?dayjs(card.enddate).format("YYYY-MM-DD"):"");
    setAssessment(card.assessment?card.assessment:"");
    setSequence(card.sequence?card.sequence:"");
    setCoachId(card.coachId?card.coachId:null);

    setModulecard(card);
    renderDetail();
  }, [rerender, card]);

  useEffect(() => {
    loadCoaches();
  }, []);

  useEffect(() => {
    renderDetail();
  }, [coaches]);

  const renderDetail = () => {
    if (modulecard === undefined || Object.keys(modulecard).length == 0 || modulecard.length == 0) {
      return <>
      </>;
    } else {
      return (
        <>
          <div className="detail detail-overlay">
            <a
              onClick={() => {setModulecard([]);onCardchange([]);}}
              className="btn closebtn"
            >
              X
            </a>
            <h2>Modulinformationen </h2>

            <div className="content">
              <h3>
              
                {modulecard.module.number} {modulecard.module.name}
              </h3>
              <h4>Objekt:</h4><p>{modulecard.module.object}</p>
              <h4>Kompetenz:</h4><p> {modulecard.module.skill}</p>
              <div className="row">
              <div className="col-6">
             <h5>Handlungskompetenzbereich:</h5> <p>{modulecard.module.actionskills}</p>
             </div>
             <div className="col-3">

              <h5>Lehrjahr:</h5><p>{modulecard.module.year}</p>
              </div>
             <div className="col-3">

              <h5>Typ:</h5><p> {modulecard.module.type}</p>
              <p>{modulecard.module.state}</p>
              </div>
              </div>
              <a
                href={`https://www.modulbaukasten.ch/module/${modulecard.module.number}`}
                target="_blank"
                rel="noreferrer"
              >
                Modulidentifikation ICT CH
              </a>

              <Dependencies dependencies={modulecard.module.dependencies} />

              <h2>Modulvereinbarung </h2>
              <div></div>
              <form className="row">
                <div className="form-group col-6">
                  <label htmlFor="startdate">Startdatum</label>
                  <input
                    onChange={(e) => {
                      if (e.target.value !== "") setStartdate(e.target.value);
                    }}
                    type="date"
                    name="startdate"
                    className="form-control"
                    value={startdate}
                  />
                </div>
                <div className="form-group col-6">
                  <label htmlFor="enddate">Abgabedatum</label>
                  <input
                    onChange={(e) => {
                      if (e.target.value !== "") setEnddate(e.target.value);
                    }}
                    type="date"
                    name="enddate"
                    className="form-control"
                    id="enddate"
                    value={enddate}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="assessment">
                    Form und Abmachungen bezüglich der Leistungsbeurteilung (LB
                    und OBA)
                  </label>
                  
                  <textarea
                    onChange={(e) => {
                      setAssessment(e.target.value);
                    }}
                    type="textarea"
                    name="assessment"
                    className="form-control"
                    id="assessment"
                    value={assessment}
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="sequence">
                    Grober Ablauf (Feedback/Termine...)
                  </label>
                  <textarea
                    onChange={(e) => {
                      setSequence(e.target.value);
                    }}
                    type="textarea"
                    name="sequence"
                    className="form-control"
                    id="sequence"
                    value={sequence}
                  />
                </div>
<div className="col-12">  
<label htmlFor="coach">Coach</label>

<select value={coachId} onChange={(e) => {
                      setCoachId(e.target.value);
                    }}>
<option key="none" value=""> Nicht ausgewählt</option>

  {coaches.map((coach) => (
    <option key={coach.id} value={coach.id}>  {coach.name} {coach.surname}</option>
  ))}
</select>
</div>
                <div className="col-6"></div>
                <div className="col-12">
                <button
              onClick={() => {setModulecard([]);onCardchange([]);}}
              type="button"
                    className="col-12 col-md-6 btn btn-cancel"
                  >
                    abbrechen
                  </button>
                  <button
                    onClick={saveAgreement}
                    type="submit"
                    className="col-12 col-md-6 btn btn-save"
                  >
                    speichern
                  </button>

               
                </div>
              </form>
            </div>
          </div>
        </>
      );
    }
  };

  return renderDetail();
}
