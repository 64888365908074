import { UserToken } from "../User/UserToken";

export const  Usermenu = () => {
	const userToken = new UserToken();
	if (userToken.isCoach()) {
		return (
			<>
				<a className="btn badge" href="/students">
					Module
				</a> |
				<a className="btn badge" href="/planner">
					Masterpläne
				</a>
				|
			<a className="btn badge" href="/info">
				Info
			</a>
				|
			</>
		);
	} else {
		return (
      <>
      <a className="btn badge" href="/">
					Masterplan
				</a>
				|
			<a className="btn badge" href="/info">
				Info
			</a>
			|
      </>
		);
	}
};

export function User() {
	const userToken = new UserToken();
	const logout = () => {
		userToken.logout();
		window.location.href = "/login";
	};

	if (userToken.isLoggedIn()) {
		return (
			<div className="user">
				Hey {userToken.getName()} |{" "}
				
				<Usermenu />
				<a className="btn badge " onClick={logout}>
					Logout
				</a>
			</div>
		);
	} else {
		return (
			<a className="btn badge" href={process.env.REACT_APP_REGISTER_URL}>
				Register
			</a>
		);
	}
}
