import { useState, useEffect, useRef } from "react";
import { Column } from "./Column";
import { DndContext, rectIntersection } from "@dnd-kit/core";
import { Moduldetails } from "../Moduledetail/Moduldetails";
import { Infomessage } from "../Navigation/infomessage";
import { Masterplan } from "./Masterplan/Masterplan";
import { UserToken } from "../User/UserToken";
import { Modul } from "./Modul";

export function Moduleplanner() {
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const [studentId, setStudentId] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [states, setStates] = useState([]);
  const [allStudents, setAllStudents] = useState([]);
  const [selectedModulecard, setSelectedModulecard] = useState([]);
  const [items, setItems] = useState([]);
  const messageRef = useRef(null);
  const [rerender, setRerender] = useState(false);
  const userToken = new UserToken();

  const loadStates = () => {
    fetch(`${API_URL}/states`, {
      headers: {
        Authorization: `Bearer ${userToken.getToken()}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.code === 401) {
            setError("You are not authorized to view this page.");
            window.location.href = "/login";
          } else {
            setStates(result["hydra:member"]);
          }
        },
        (error) => {
          setError(error);
        }
      );
  };

  const loadAllStudents = () => {
    fetch(`${API_URL}/coach/allstudents`, {
      headers: {
        Authorization: `Bearer ${userToken.getToken()}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.code === 401) {
            setError("You are not authorized to view this page.");
            window.location.href = "/login";
          } else {
            setAllStudents(result);
          }
        },
        (error) => {
          setError(error);
        }
      );
  };

  useEffect(() => {
    if (items.length === 0) return;
    userToken.setSpecialisation(items.specialisation.specialisation);
  }, [items]);
  useEffect(() => {
    renderModulplaner();
  }, [rerender]);

  useEffect(() => {
    loadAllStudents();
  }, []);

  useEffect(() => {
    loadItems(studentId);
  }, [studentId]);

  useEffect(() => {
    coachHeader();
  }, [allStudents]);

  useEffect(() => {
    if (error) {
      alert(
        "Da ist was schief gelaufen. Bitte melde dich bei Manuel mit folgender Fehlermeldung: " +
          error
      );
      window.location.href = "/login";
    }
  }, [error]);

  const loadItems = async (uid) => {
    if (
      uid === null ||
      uid == "" ||
      (userToken.isCoach() && parseInt(userToken.getUserId()) == parseInt(uid))
    )
      return;
    fetch(`${API_URL}/users/${uid}`, {
      headers: {
        Authorization: `Bearer ${userToken.getToken()}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          if (result.code === 401) {
            setError("You are not authorized to view this page.");
            window.location.href = "/login";
          } else {
            setItems(result);
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  };

  const selectModule = (id) => {
    const newSelectedModulecard = items.modulecards.find(
      (modulecard) => parseInt(modulecard.id) === parseInt(id)
    );
    setSelectedModulecard({}); //Force change to rerender
    setSelectedModulecard(newSelectedModulecard);
  };

  const updateModulcardState = (moduleCardId, newState) => {
    let body;

    const selectedState = states.find((state) => state.state === newState);
    const updatedItems = items;

    updatedItems.modulecards.map((modulecard) => {
      if (
        modulecard.id === moduleCardId &&
        modulecard.state !== selectedState.state
      ) {
        modulecard.state = selectedState;
        body = {
          state: selectedState["@id"], //Must be string (IRI)
        };
        saveModulecard(moduleCardId, body);
      }
      return modulecard;
    });
  };

  const coachHeader = () => {
    if (userToken.isCoach()) {
      return (
        <div className="studentselector form-group">
          <select
            className="col-6 form-control"
            id="student"
            onChange={(e) => {
              setStudentId(e.target.value);
              loadItems(e.target.value);
            }}
          >
            <option value="">Wähle einen Lernenden</option>

            {allStudents.map((student) => (
              <option value={student.id}>
                {student.name} {student.surname}
              </option>
            ))}
          </select>
        </div>
      );
    }
  };

  const renderModulplaner = () => {
    return (
      <>
        <div className="modulplanner container-fluid">
          <Infomessage reference={messageRef} info="saving" />

          <div className="row">
            <DndContext
              collisionDetection={rectIntersection}
              onDragEnd={(e) => {
                if (e.over) {
                  updateModulcardState(e.active.id, e.over?.id);
                } else {
                  console.log("id is null");
                }
              }}
            >
              <Column
                key="key-offen"
                modulecards={items.modulecards}
                state="Offen"
                col="col-3 col-xl-4"
                selectmodule={selectModule}
                comparator={(a, b) => a.module.year - b.module.year}
              />
              <Masterplan
                modulecards={items.modulecards}
                state="Geplant"
                col="col-6 col-xl-4"
                selectmodule={selectModule}
                comparator={(a, b) =>
                  new Date(a.startdate).getTime() -
                  new Date(b.startdate).getTime()
                }
                workdays={items.workdays}
                key="key-geplant"
              />
              <Column
                modulecards={items.modulecards}
                state="Abgeschlossen"
                col="col-3 col-xl-4"
                selectmodule={selectModule}
                comparator={(a, b) =>
                  new Date(a.enddate).getTime() - new Date(b.enddate).getTime()
                }
                key="key-abgeschlossen"
              />
              <Moduldetails
                card={selectedModulecard}
                saveModulecard={saveModulecard}
                onCardchange={updateModulcard}
              />
            </DndContext>
          </div>
        </div>
      </>
    );
  };


  const updateModulcard = (updatedCard) => {
    if (updatedCard === undefined) {
      setSelectedModulecard({});
    }
    setSelectedModulecard(updatedCard);

    const updatedItems = items;

    items.modulecards.map((modulecard) => {
      if (modulecard.id === updatedCard.id) {
        modulecard = updatedCard;
      }
    });
    setRerender(!rerender);
  };

  const saveModulecard = (modulecardId, body, callback) => {
    messageRef.current.classList.add("show");
    fetch(`${API_URL}/module_cards/${modulecardId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        Authorization: `Bearer ${userToken.getToken()}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return Promise.reject(res);
      })
      .then((result) => {
        messageRef.current.classList.remove("show");
      })
      .catch((error) => {
        alert(
          "Leider gab es einen Fehler beim Speichern. Bitte fülle alle Felder aus."
        );
        //setError(error);
        messageRef.current.classList.remove("show");
      });
  };

  useEffect(() => {
    const userToken = new UserToken();
    loadStates();
    if (userToken.isLoggedIn()) {
      loadItems(userToken.getUserId());
    } else {
      setError("Der Benutzer ist nicht eingeloggt.");
    }
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    if (userToken.isCoach()) {
      return (
        <>
          {coachHeader()}
          <div className="loadinginfo">Wähle einen Lernenden</div>
        </>
      );
    } else {
      return (
        <>
          <div className="loading"> </div>
        </>
      );
    }
  } else {
    return (
      <>
        {coachHeader()}
        {renderModulplaner()}
      </>
    );
  }
}
