import jwt_decode from "jwt-decode";

export class UserToken {
	constructor() {
		this.studentUserId = -1;
		this.setStudentUserId = (id) => {
			this.studentUserId = id;
		};

		this.getToken = () => {
			return localStorage.getItem("token");
		};

		this.setSpecialisation = (s) => {
			localStorage.setItem("specialisation",s);
		};

		this.getSpecialisation = () => {
			return localStorage.getItem("specialisation");
		};

		this.getUserId = () => {
			return this.userId;
		};

		this.getStudentUserId = () => {
			if (this.isCoach()) {
				return this.studentUserId;
			}
			return this.userId;
		};

		this.isLoggedIn = () => {
			try {
				return (
					localStorage.getItem("token") !== null &&
					this.getDecodedToken().exp > Date.now() / 1000
				);
			} catch (e) {
				return false;
			}
		};

		this.isCoach = () => {
			return this.isLoggedIn() && this.getRoles().includes("ROLE_COACH");
		};

		this.logout = () => {
			localStorage.removeItem("token");
			this.userId = null;
		};

		this.login = (token) => {
			localStorage.setItem("token", token);
			this.userId = this.getDecodedToken().userid;
		};

		this.getDecodedToken = () => {
			return jwt_decode(this.getToken());
		};

		this.getRoles = () => {
			return this.getDecodedToken().roles;
		};

		this.getUserName = () => {
			return this.getDecodedToken().username;
		};

		this.getName = () => {
			return this.getDecodedToken().name;
		};

		this.getCalHash = () => {
			return this.getDecodedToken().calHash;
		};

		this.reloadUserId = () => {
			if (this.isLoggedIn()) {
				this.userId = this.getDecodedToken().userid;
			}
		};

		this.reloadUserId();
	}
}
